import React from "react"
import { Button, Input } from "reactstrap"
import { Link, useStaticQuery, graphql } from "gatsby"

// function to create arrays
// given ["Test", "Another Test"]  / ["test", "another-test"]
// return [["Test", "test"], ["Another Test", "another-test"]]
const zip = (arr, ...arrs) => {
  return arr.map((val, i) => arrs.reduce((a, arr) => [...a, arr[i]], [val]))
}

export default function ({ search, count, totalCount }) {
  const filterData = graphql`
    {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(projects)/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        nodes {
          fields {
            categories
          }
          frontmatter {
            categories
          }
        }
      }
    }
  `
  const { data } = useStaticQuery(filterData)

  // recreates a flatted and unique category list
  const frontmatterCategories = [
    ...new Set(
      [...new Set(data.nodes.map(a => [a.frontmatter.categories]))]
        .flat()
        .flat()
    ),
  ]
  // recreates a flatted and unique category list
  const fieldsCategories = [
    ...new Set(
      [...new Set(data.nodes.map(a => [a.fields.categories]))].flat().flat()
    ),
  ]
  const result = zip(frontmatterCategories, fieldsCategories)
  return (
    <div className="header">
      <div className="fsp-filter">
        <div className="filter-title">
          <i className="fal fa-filter"></i>
          <span>Projekt Filter</span>
        </div>
        <div className="gallery-filters">
          <Link
            data=""
            to="#"
            className="gallery-filter gallery-filter-active"
            onClick={e => {
              search(e)
            }}
          >
            All
          </Link>
          {result.map((cat, idx) => {
            return (
              <Link
                key={idx}
                data={cat[1]}
                to="#"
                className="gallery-filter gallery-filter-active"
                onClick={e => {
                  search(e)
                }}
              >
                {cat[0]}
              </Link>
            )
          })}
        </div>
        <div className="folio-counter">
          <div className="num-album">{count}</div>
          <div className="all-album">{totalCount}</div>
        </div>
      </div>
    </div>
  )
}
