import React from "react"
import Footer from "../components/footer/footer"
import ProjecsIndex from "../components/projects/ProjectsIndex"
import ContactMe from "../components/contact/ContactMe"

const Projects = () => {
  return (
    <div id="wrapper" className="single-page-wrap">
      <ProjecsIndex />
      <div className="height-emulator fl-wrap"></div>
      <Footer />
      <ContactMe />
    </div>
  )
}

export default Projects
