import React, { useState } from "react"
import GetInTouch from "../get-in-touch/get-in-touch"
import { Link, useStaticQuery, graphql } from "gatsby"
import Masonry from "react-masonry-component"
import Filter from "./filter"
import DirectionReveal from "./direction-reveal"
import { LightgalleryItem } from "react-lightgallery"
import "./direction-reveal.scss"
import ContactMe from "../contact/ContactMe"
const directionReveal = DirectionReveal({
  selector: ".direction-reveal",
  itemSelector: ".direction-reveal__card",
  animationName: "slide",
  animationPostfixEnter: "enter",
  animationPostfixLeave: "leave",
  enableTouch: true,
  touchThreshold: 100,
})

const ProjectsIndex = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(projects)/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        totalCount
        nodes {
          id
          fields {
            category
            slug
            categories
          }
          frontmatter {
            name
            date(formatString: "YYYY")
            category
            featuredImage {
              id
              childImageSharp {
                fluid(maxWidth: 1400, maxHeight: 1400, toFormat: JPG) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                resize(cropFocus: CENTER, height: 300, width: 300) {
                  src
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)

  const defaultState = { data: data, filter: "" }

  const [state, setState] = useState(defaultState)

  const search = e => {
    e.preventDefault()
    setState({ ...state, filter: e.target.getAttribute("data") })
  }

  // const searchResult = state.data.projects.nodes.filter(node => {
  //   return node.fields.category.toLowerCase().indexOf(state.filter) != -1
  // })

  const reduceResult =
    state.filter.length === 0
      ? state.data.projects.nodes
      : state.data.projects.nodes.reduce((res, node) => {
          const items = node.fields.categories.filter(
            category => category === state.filter
          )
          if (items.length > 0) res.push({ ...node, items })
          return res
        }, [])

  const childElements = reduceResult.map((element, idx) => {
    return (
      <div key={idx} className="gallery-item direction-reveal__card">
        <div className="grid-item-holder">
          <LightgalleryItem
            itemClassName=""
            group="vj"
            downloadUrl="false"
            src={element.frontmatter.featuredImage.childImageSharp.resize.src}
          >
            <Link className="fet_pr-carousel-box-media-zoom image-popup" to="#">
              <i className="fal fa-search"></i>
            </Link>
          </LightgalleryItem>

          <Link to={element.fields.slug}>
            <img
              src={element.frontmatter.featuredImage.childImageSharp.resize.src}
              alt={element.frontmatter.name}
            />
          </Link>
          <div className="box-item hd-box direction-reveal__anim--enter">
            <Link to={element.fields.slug} className=" fl-wrap full-height">
              <div className="hd-box-wrap">
                <h2>{element.frontmatter.name}</h2>
                <p>{element.frontmatter.category}</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  })

  return (
    <>
      <div className="content">
        <div className="single-page-decor"></div>
        <Filter
          search={search}
          totalCount={state.data.projects.totalCount}
          count={childElements.length}
        />

        <section className="no-padding dark-bg">
          <div className="gallery-items min-pad hde four-column">
            <Masonry
              className="direction-reveal"
              options={{ transitionDuration: 500 }}
              disableImagesLoaded={false}
              updateOnEachImageLoad={false}
            >
              {childElements}
            </Masonry>
          </div>
        </section>

        <GetInTouch />
      </div>

      <div className="height-emulator fl-wrap"></div>

      <ContactMe />
    </>
  )
}

export default ProjectsIndex
